


















import {defineComponent, ref} from "@vue/composition-api";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import VuexStore from "../store";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import firebase from "firebase/compat";

export default defineComponent({
  name: 'Login',
  props: {},
  setup(props, context) {
    const loading = ref(true);
    const currentUser = ref(null);
    const auth = getAuth()
    onAuthStateChanged(auth, (state)=>{
      loading.value = false;
      currentUser.value = auth.currentUser
      if(currentUser.value) return;
      const ui = new firebaseui.auth.AuthUI(auth);
      ui.start('#firebaseui-auth-container', {
        signInOptions: [
          firebase.auth.GoogleAuthProvider.PROVIDER_ID,
          firebase.auth.EmailAuthProvider.PROVIDER_ID
        ],
        signInFlow: 'redirect',
        callbacks: {
          // signInFailure callback must be provided to handle merge conflicts which
          // occur when an existing credential is linked to an anonymous user.
          signInFailure: function (error) {
            alert("Login failed " + JSON.stringify(error));
          },
          signInSuccessWithAuthResult(authResult: any, redirectUrl?: string): boolean {
            console.log("SignInSuccess");
            // If the user has permission to write the admin collection, they're an admin user
            const profile = authResult.additionalUserInfo.profile;
            handleAuth(profile);
            return false;
          }
        }
      });
    });

    const handleAuth = async (profile: any) => {
      // Update Vuex and redirect
      // VuexStore.commit("setIsAuthenticated", true);
      // window.location.href = "/"
    }

    const logout = () => {
      // console.warn("logout")
      // firebase.auth().signOut()
      //     .then(() => {
      //       VuexStore.commit("logout");
      //     })
    }

    return {
      logout,
      currentUser,
      handleAuth,
      loading
    }
  }
});
